export const peopleAlsoAskSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "Does Mahindra have an electric car?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, Mahindra has a range of electric vehicles, including the Mahindra e2oPlus and the Mahindra Treo. These electric cars are known for their impressive performance, durability, and eco-friendliness. To learn more about Mahindra's electric cars, you can visit the Mahindra page on the Turno website.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How much does a Piaggio Ape cost?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The cost of a Piaggio Ape may vary depending on the model and location. For instance, the Piaggio Ape Xtra LDX is priced at around INR 2.3 lakhs in India. To know more about the Piaggio Ape and its pricing, you can visit the Piaggio page on the Turno website.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Is Piaggio an Indian company?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "No, Piaggio is an Italian company that manufactures a range of vehicles, including three-wheelers, motorcycles, and scooters. Piaggio has a strong presence in India, where it offers a range of vehicles, including the popular Piaggio Ape. To learn more about Piaggio, you can visit the Piaggio page on the Turno website.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What does Turno company do?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Turno is a company that provides electric vehicle solutions for businesses. Turno offers a range of electric vehicles, including three-wheelers, four-wheelers, and pickups. Turno's vehicles are known for their impressive performance, durability, and eco-friendliness. To know more about what Turno does, you can visit the Turno page on the Turno website.",
        url: "https://www.turno.club/finance",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is the cost of a 3 wheeler auto in India?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The cost of a 3-wheeler auto in India may vary depending on the brand and model. For instance, the Piaggio Ape Xtra LDX is priced at around INR 2.3 lakhs, while the Mahindra Treo is priced at around INR 2.7 lakhs. To learn more about the pricing of 3-wheeler autos in India, you can visit the Vehicles page on the Turno website.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is the cost of the Ape electric auto?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The cost of the Ape electric auto may vary depending on the model and location. For instance, the Piaggio Ape E-City is priced at around INR 2.83 lakhs in India. To know more about the Ape electric auto and its pricing, you can visit the Piaggio page on the Turno website.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },

    {
      "@type": "Question",
      name: "What is the cost of an electric three-wheeler?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The cost of an electric three-wheeler may vary depending on the brand and model. For instance, the Mahindra Treo is priced at around INR 2.7 lakhs, while the Piaggio Ape E-City is priced at around INR 2.83 lakhs. To learn more about the pricing of electric three-wheelers, you can visit the Vehicles page on the Turno website.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is the load capacity of an EV vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The load capacity of an EV vehicle may vary depending on the brand and model. For instance, the Mahindra Treo has a load capacity of 500 kg, while the Piaggio Ape has a load capacity of 750 kg. To know more about the load capacity of EV vehicles, you can visit the Vehicles page on the Turno website.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is the load capacity of Mahindra pickup 1.7 T?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The Mahindra 1.7 T pickup has a maximum payload capacity of 1,710 kg.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is the mileage of Mahindra Treo?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The Mahindra Treo has a range of up to 130 km on a single charge.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is the mileage of Piaggio auto?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The mileage of Piaggio's electric auto varies depending on the model and usage. Please visit the Piaggio electric auto page on Turno's website to learn more.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is the price of 3 wheeler tempo electric car?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The price of a 3-wheeler tempo electric car varies depending on the model and specifications. Please visit the Electric Auto page on Turno's website to learn more.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is the price of 3 wheeler tempo in India?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The price of a 3-wheeler tempo in India varies depending on the make, model, and specifications. Please visit the Electric Auto page on Turno's website to learn more.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is the price of ape auto passenger 2023?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The price of the Piaggio Ape auto passenger in 2023 may vary based on various factors such as location and specifications. Please visit the Piaggio electric auto page on Turno's website to learn more.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },

    {
      type: "Question",
      name: "What is the price of Ape Max?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The price of the Piaggio Ape Max may vary based on various factors such as location and specifications. Please visit the Piaggio electric auto page on Turno's website to learn more.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      type: "Question",
      name: "What is the price of electric 3 wheeler auto in India?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The price of an electric 3-wheeler auto in India varies depending on the make, model, and specifications. Please visit the Electric Auto page on Turno's website to learn more.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      type: "Question",
      name: "What is the price of Mahindra 12 foot truck?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The price of a Mahindra 12 foot truck may vary based on various factors such as location and specifications. Please visit the Mahindra electric auto page on Turno's website to learn more.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      type: "Question",
      name: "What is the price of Mahindra electric auto 4 wheeler?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The price of a Mahindra electric auto 4-wheeler may vary based on various factors such as location and specifications. Please visit the Mahindra electric auto page on Turno's website to learn more.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      type: "Question",
      name: "What is the price of Piaggio electric car?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The price of a Piaggio electric car may vary based on various factors such as location and specifications. Please visit the Piaggio electric auto page on Turno's website to learn more.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      type: "Question",
      name: "What is the price of Treo?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "You can find the price of the Mahindra Treo electric three-wheeler on our website. Please visit the Vehicles page to see the prices of all the electric vehicles we offer.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      type: "Question",
      name: "What is the price range of Mahindra Treo Zor?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The Mahindra Treo Zor comes in multiple variants, and the price range depends on the model you choose. Please visit the Vehicles page and select the Mahindra brand to view the different models and their prices.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      type: "Question",
      name: "Which battery is used in Mahindra Treo?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The Mahindra Treo electric three-wheeler uses a Lithium-ion battery, which is known for its high energy density and longer life.",
        url: "https://www.turno.club/vehicles/mahindra",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },

    {
      "@type": "Question",
      name: "Which country brand is Piaggio auto?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Piaggio is an Italian brand that produces a range of vehicles, including the Piaggio Ape Boss electric three-wheeler.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Which is the electric two-wheeler?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "We offer a variety of electric vehicles, including two-wheelers, such as the OSM Rage and the Etrio electric scooter. Please visit our Vehicles page and select the type of vehicle you're interested in to see our offerings.",
        url: "https://www.turno.club/vehicles",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Who is Piaggio owned by?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The Piaggio Group is owned by the Piaggio family and is based in Italy. It produces a range of vehicles, including the popular Vespa scooter.",
        url: "https://www.turno.club/vehicles/piaggio",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Who is the founder of Etrio?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Etrio is an Indian electric vehicle company founded by Deepak MV in 2017. They specialize in manufacturing electric three-wheelers for commercial use.",
        url: "https://www.turno.club/vehicles/ETRIO",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },

    {
      "@type": "Question",
      name: "What is Turno.club and why should I buy my EV through Turno?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Turno is a one stop destination to buy all best selling commercial electric vehicles available in the market. We help buyers to choose the best electric 3 wheeler vehicle for their business along with easy financing and assured resale.",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Does Turno have an exchange offer?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, Turno has an exchange offer where you can sell your old vehicle and get a new commercial electric 3 wheeler. To know more about our exchange offer, contact us on 08047842233.",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What are the financial schemes Turno provides on three wheeler electric auto?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Turno gives the lowest downpayment of ₹49,999 on commercial electric vehicles with lowest interest rate of 12.49%. We also provide EV financing with low processing fee, zero pre-closure charges and zero hidden charges.\n\n",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What happens after I pay ₹499 on the website to book my test drive?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "After you complete the online payment of ₹499 to book an electric vehicle, an exclusive Turno EV expert will be assigned to take you through further process and schedule a test drive. In case you change your mind, we will initiate the full refund of your booking fee.",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Can I exchange my old vehicle for a new electric vehicle through Turno?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "At Turno, we do have a vehicle exchange offer. If you are interested in buying a new electric commercial vehicle from Turno, you can mention that to us while filling up the online form and we will take care of the rest.",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is the Turno warranty on an electric vehicle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "We provide 3 years of warranty on the electric vehicle battery. Also, if you wish to sell your vehicle to Turno, we provide a guaranteed resale value up to ₹1.5 lakhs after 3 years on commercial electric three wheeler vehicles.",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "Will Turno help me get a Government subsidy on electric cargo vehicles?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Absolutely! The quotation from Turno includes subsidies and registration charges for EV. We also provide assistance in documentation to avail the electric vehicle subsidy.\n\n",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How will I find the EV that suits my business?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Turno has partnered with the best-selling commercial vehicles because we understand that every business has different needs. You can contact our Turno EV experts to ensure that you buy an electric gadi that best fits your business.\n\n",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "How long will it take to deliver my EV once I pay the booking amount?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Generally, it takes us 5 days to deliver the vehicle after the booking amount is paid.\n\n",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "I don’t know my credit score. Can I still get the financing?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "At Turno, we assure 100% on-road financing for electric vehicles. To know more about EV financing, kindly contact Turno EV experts today on 08047842233.\n",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
    {
      "@type": "Question",
      name: "What is the longest and shortest possible tenure of my EV loan?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "With Turno, you can avail EV financing for a tenure of 12 to 48 months. Turno, also offers on-road with no pre-closure or processing fee. We have no documentation or hidden charges.",
        url: "https://www.turno.club/",
        dateCreated: "2023-05-11",
        author: {
          "@type": "Organization",
          name: "Turno",
        },
      },
    },
  ],
};
