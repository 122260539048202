export const productCarouselSchema = {
  "@context": "https://schema.org",
  "@type": "ItemList",
  name: "Product Carousel",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "Piaggio Ape Electrik",
      url: "https://www.turno.club/vehicles/piaggio",
      image: [
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/piaggio/jpg/p1.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/piaggio/jpg/p2.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/piaggio/jpg/p3.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/piaggio/jpg/p4.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/piaggio/jpg/p5.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/piaggio/jpg/p6.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/piaggio/jpg/p7.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/piaggio/jpg/p8.jpg",
      ],
      description:
        "The Piaggio Ape Electrik, or Ape E-City, is the electric variant of the Ape Auto. It features a 5.36 horsepower electric motor and an 8 kWh lithium-ion battery pack that provides a range of up to 80 km on a single charge. The Ape Electric can be charged using a standard household outlet or a fast-charging station in 3-4 hours. With a payload capacity of 550 kg and an open cabin configuration, it is suitable for goods transportation and commercial use. Compared to the petrol/diesel version, it has a lower power output of 4.8 horsepower and a top speed of 45 km/h, but boasts zero emissions and lower running costs.The Piaggio Ape Electrik, or Ape E-City, is competitively priced, making it an affordable option for those looking for an electric vehicle. The exact price may vary depending on the region and local taxes or incentives, but as of 2023, the approximate starting price for a Piaggio Ape Electrik is around ₹ 3.7L. ",
      brand: {
        "@type": "Brand",
        name: "Piaggio",
      },

      offers: {
        "@type": "AggregateOffer",
        offerCount: 4,
        lowPrice: 360000,
        highPrice: 380000,
        priceCurrency: "INR",
        url: "https://www.turno.club/vehicles/piaggio",
        seller: { "@type": "Organization", name: "Turno" },
      },
    },

    {
      "@type": "ListItem",
      position: 2,
      name: "Mahindra Treo Zor",
      url: "https://www.turno.club/vehicles/mahindra",
      image: [
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/mahindra/jpg/m1.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/mahindra/jpg/m2.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/mahindra/jpg/m3.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/mahindra/jpg/m4.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/mahindra/jpg/m5.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/mahindra/jpg/m6.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/mahindra/jpg/m7.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/mahindra/jpg/m8.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/mahindra/jpg/m9.jpg",
      ],
      description:
        "Mahindra & Mahindra, the pioneering electric vehicle technology company in India, produces the Mahindra Treo Zor, an electric three-wheeler that provides a sustainable and cost-effective mode of transportation for last-mile delivery. Equipped with a lithium-ion battery pack and a powerful motor, the Treo Zor provides a range of up to 80 km on a single charge, making it ideal for small business owners and delivery services. It offers various features such as a digital instrument cluster, mobile charging ports, and a spacious cabin for passengers. The Treo Zor has a huge load carrying capacity and provides fuel cost savings of Rs. 2.10/km when compared to diesel cargo 3 wheelers, making it an attractive option for those in need of an electric three-wheeler. Additionally, it is environmentally friendly, producing zero emissions and has a maintenance-free ride with battery life exceeding 1.50 lakh km. For more information or to book the Treo Zor, contact Turno today. Mahindra Treo Zor price starts at Rs ₹3.13 Lakhs which varies from state and city. The lowest price model is 2216/Flat Bed. Mahindra Treo Zor is a 3 wheeler commercial vehicle. It is available in 3 variants. ",
      brand: {
        "@type": "Brand",
        name: "Mahindra",
      },
      offers: {
        "@type": "AggregateOffer",
        offerCount: 5,
        lowPrice: 320000,
        highPrice: 340000,
        priceCurrency: "INR",
        url: "https://www.turno.club/vehicles/mahindra",
        seller: { "@type": "Organization", name: "Turno" },
      },
    },

    {
      "@type": "ListItem",
      position: 3,
      name: "Etrio Touro MAX+",
      url: "https://www.turno.club/vehicles/ETRIO",
      image: [
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/etrio/jpg/e1.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/etrio/jpg/e2.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/etrio/jpg/e3.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/etrio/jpg/e4.jpg",
      ],
      description:
        "E-trio Touro Max is a 3 wheeler cargo auto and one of the bestselling electric cargo vehicles from E-trio. The Touro Max electric vehicle has several advantages over conventional internal combustion engine (ICE) vehicles. The first and most obvious advantage is that it has zero emissions and doesn't require fuels. As electricity is less expensive than gasoline, electric vehicles can be a great choice for those looking for a cargo vehicle with a lower operating cost. This cargo electrical auto is also considerably more comfortable to run due to its gearless and noiseless operations.The E-trio Touro Max cargo 3-wheeler boasts a spacious cargo area measuring 5.68 feet in length, 4.72 feet in width, and 1.25 feet in height, enabling it to transport a variety of items. It is equipped with a powerful engine, providing a maximum speed of 45 km/h.This electric three wheeler is well known for its high load bearing capacity. This commercial EV can be used for transporting gas cylinders, water cans, logistics, and many others.To know more about E-trio Touro Max or to book it, contact Turno today.",
      brand: {
        "@type": "Brand",
        name: "E-trio",
      },
      offers: {
        "@type": "AggregateOffer",
        offerCount: 5,
        lowPrice: 390000,
        highPrice: 410000,
        priceCurrency: "INR",
        url: "https://www.turno.club/vehicles/ETRIO",
        seller: { "@type": "Organization", name: "Turno" },
      },
    },

    {
      "@type": "ListItem",
      position: 4,
      name: "OSM Rage +",
      url: "https://www.turno.club/vehicles/OSM",
      image: [
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/osm/jpg/o1.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/osm/jpg/o2.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/osm/jpg/o3.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/osm/jpg/o4.jpg",
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/osm/jpg/o5.jpg",
      ],
      description:
        "OSM Rage+ Electric Cargo Auto is an innovative solution for last-mile delivery of goods. This electric vehicle is specifically designed for the purpose of delivering packages and cargo in urban areas.Some key features of the OSM Electric Cargo Auto include a spacious cargo area, versatile loading options, and efficient battery technology. The vehicle is equipped with advanced safety features and has been rigorously tested to meet various safety standards. Additionally, the vehicle can be easily integrated into existing delivery operations, making it a cost-effective and practical solution for businesses.The electric mechanism of the OSM EV has various advantages over conventional internal combustion engine (ICE) vehicles. One advantage is that it has zero emission making it considerably more environmentally friendly. Also, as the name suggests, it requires electricity as fuel making it considerably more affordable to run.Overall, the OSM Rage+ is a versatile and efficient electric load vehicle that is well-suited for use in urban environments. Its compact design, low operating costs, and zero emissions make it an attractive option for businesses and individuals looking for a cost-effective and environmentally friendly transportation solution. The metal body and strong doors of this commercial battery auto make it an ideal choice for all.For more information on OSM Rage + or to book online, contact Turno today.",
      brand: {
        "@type": "Brand",
        name: "OSM(Omega Seiki Mobility)",
      },
      offers: {
        "@type": "AggregateOffer",
        offerCount: 5,
        lowPrice: 390000,
        highPrice: 410000,
        priceCurrency: "INR",
        url: "https://www.turno.club/vehicles/OSM",
        seller: { "@type": "Organization", name: "Turno" },
      },
    },
  ],
};
