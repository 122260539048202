export const piaggioImagePacks = {
  "@context": "https://schema.org/",
  "@type": "ItemList",
  name: "Piaggio Ape Electric (Electrik)",
  itemListElement: [
    {
      "@type": "ImageObject",
      contentUrl: "https://www.turno.club/vehicles/piaggio",
      caption: "Piaggio Ape Electric (Electrik) front view",
      thumbnail:
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/piaggio/jpg/p1.jpg",
    },
    {
      "@type": "ImageObject",
      contentUrl: "https://www.turno.club/vehicles/piaggio",
      caption: "Piaggio Ape Electric (Electrik) side view 1",
      thumbnail:
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/piaggio/jpg/p2.jpg",
    },
    {
      "@type": "ImageObject",
      contentUrl: "https://www.turno.club/vehicles/piaggio",
      caption: "Piaggio Ape Electric (Electrik) side view 2",
      thumbnail:
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/piaggio/jpg/p3.jpg",
    },
    {
      "@type": "ImageObject",
      contentUrl: "https://www.turno.club/vehicles/piaggio",
      caption: "Piaggio Ape Electric (Electrik) rear view 1",
      thumbnail:
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/piaggio/jpg/p4.jpg",
    },
    {
      "@type": "ImageObject",
      contentUrl: "https://www.turno.club/vehicles/piaggio",
      caption: "Piaggio Ape Electric (Electrik) rear view 2",
      thumbnail:
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/piaggio/jpg/p5.jpg",
    },

    {
      "@type": "ImageObject",
      contentUrl: "https://www.turno.club/vehicles/piaggio",
      caption: "Piaggio Ape Electric (Electrik) rear view 6",
      thumbnail:
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/piaggio/jpg/p6.jpg",
    },
  ],
};

export const productCarousels = {
  "@context": "https://schema.org",
  "@type": "ItemList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      url: "https://www.turno.club/vehicles/piaggio",
    },
    {
      "@type": "ListItem",
      position: 2,
      url: "https://www.turno.club/vehicles/mahindra",
    },
    {
      "@type": "ListItem",
      position: 3,
      url: "https://www.turno.club/vehicles/ETRIO",
    },
    {
      "@type": "ListItem",
      position: 4,
      url: "https://www.turno.club/vehicles/OSM",
    },
  ],
};

export const mahindraImagePacks = {
  "@context": "https://schema.org/",
  "@type": "ItemList",
  name: "Mahindra Treo zor",
  itemListElement: [
    {
      "@type": "ImageObject",
      contentUrl: "https://www.turno.club/vehicles/mahindra",
      caption: "Mahindra Treo zor front view",
      thumbnail:
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/mahindra/jpg/m1.jpg",
    },
    {
      "@type": "ImageObject",
      contentUrl: "https://www.turno.club/vehicles/mahindra",
      caption: "Mahindra Treo zor side view",
      thumbnail:
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/mahindra/jpg/m2.jpg",
    },
    {
      "@type": "ImageObject",
      contentUrl: "https://www.turno.club/vehicles/mahindra",
      caption: "Mahindra Treo zor side view 1",
      thumbnail:
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/mahindra/jpg/m3.jpg",
    },
    {
      "@type": "ImageObject",
      contentUrl: "https://www.turno.club/vehicles/mahindra",
      caption: "Mahindra Treo zor side view 2",
      thumbnail:
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/mahindra/jpg/m4.jpg",
    },
    {
      "@type": "ImageObject",
      contentUrl: "https://www.turno.club/vehicles/mahindra",
      caption: "Mahindra Treo zor rear view",
      thumbnail:
        "https://assets.turnoclub.com/developer/offer-turno-images/S3/360DegreeView/mahindra/jpg/m5.jpg",
    },
  ],
};
